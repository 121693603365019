import axios from 'axios';
import store from '../store';

const $http = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	transformResponse: [
		...axios.defaults.transformResponse,
		function(data) {
			if (data) {
				if (data['$front_event']) {
					let event = data['$front_event'];
					let payload = data['$front_event_data'] ? data['$front_event_data'] : {};
					store.dispatch('handle_event', { event, data: payload });
				}
			}
			return data;
		},
	],
});
$http.defaults.withCredentials = true;

export { $http };
