import Vue from "vue";
import router from "./router";
import store from "./store";

import App from "./App.vue";

import { $http } from "./plugins/axios"
import createHasuraInstance from './plugins/hasura';
import "./plugins/theme"
import "./plugins/masonry"

Vue.prototype.$http = $http;

;(async () => {
	const $orm = createHasuraInstance(store.state.auth.jwt_token)
	Vue.prototype.$orm = $orm;

	await store.dispatch('init', $orm);

	//start vue
	Vue.config.productionTip = false;
	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount("#app");
})()