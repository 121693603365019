import __ from '../plugins/utils';
import dayjs from '@/plugins/day'

import { getCampaignsWithStatsAndAggregate } from '@/plugins/hasura/queries'

export default {
	namespaced: true,
	state: {
		items: [],
		dates: {
			startDate: dayjs().add(-2, 'day').startOf('day'),
            endDate: dayjs().add(1, 'day').startOf('day')
		}
	},
	getters: {
		
	},
	mutations: {
		set_items: (state, items) => (state.items = items)
	},
	actions: {
		get_items: async ({ rootState, rootGetters, state, commit }) => {
			if(rootGetters['auth/authenticated']) {
				var [err, response] = await rootState.$orm.queryRAW({
					query: getCampaignsWithStatsAndAggregate,
					variables: state.dates
				})
				if(err) {
					console.error('/store/campaign', 'actions.get_items', '$orm.queryRAW', err)
					return $.NotificationApp.send("Error", "Can't get campaigns", "top-right", "", "error")
				}
	
				await commit('set_items', response.campaign);
			}
		}
	},
};
