export default {
    path: '/',
    component: () => import('@/views/campaign/Layout'),
    children: [
        {
            name: 'Links',
            path: '/:campaign/links',
            component: () => import('@/views/campaign/Links'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'Tags',
            path: '/:campaign/tags',
            component: () => import('@/views/campaign/Tags'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'Dashboard',
            path: '/:campaign/dashboard',
            component: () => import('@/views/campaign/Dashboard'),
            meta: {
                authRequired: true
            },
        },
        {
            name: 'Hits',
            path: '/:campaign/hits',
            component: () => import('@/views/campaign/Hits'),
            meta: {
                authRequired: true
            },
        },
    ]
}