export function generateTables(orm) {
    orm
		.createTable({ name: 'user' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'email' })
			.createField({ name: 'is_approved' })
		.generateBaseFragments()
	
	orm
		.createTable({ name: 'campaign' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'created_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'links' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'redirect_link' })
			.createField({ name: 'short_link' })
			.createField({ name: 'created_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'tags' })
			.createField({ name: 'id', isPrimary: true })
			.createField({ name: 'name' })
			.createField({ name: 'created_at' })
		.generateBaseFragments()

	orm
		.createTable({ name: 'links2tags' })
			.createField({ name: 'link_id', isPrimary: true })
			.createField({ name: 'tag_id', isPrimary: true })
		.generateBaseFragments()

	orm
		.createTable({ name: 'stats_total_user_hits_byhour' })
			.createField({ name: 'hour' })
			.createField({ name: 'hits' })
			.createField({ name: 'users' })
		.generateBaseFragments()
		
    return orm
}