export default {
    path: '/auth',
    component: () => import('@/views/EmptyLayout'),
    children: [
        {
            name: 'Login',
            path: 'login',
            component: () => import('@/views/empty/Login'),
            meta: {
                logedoutRequired: true
            },
        },
        {
            name: 'Signup',
            path: 'signup',
            component: () => import('@/views/empty/Signup'),
            meta: {
                logedoutRequired: true
            },
        },
        {
            name: 'PasswordRecover',
            path: 'recover',
            component: () => import('@/views/empty/PasswordRecover'),
            meta: {
                logedoutRequired: true
            },
        },
        {
            name: 'Approve',
            path: 'approve',
            component: () => import('@/views/empty/Approve'),
            meta: {
                authRequired: true
            },
        }
    ],
}