import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import __ from "../plugins/utils"

Vue.use(Vuex);

import auth from './auth';
import navigation from './navigation';
import campaign from './campaign';

export default new Vuex.Store({
	state: {
		$orm: null
	},
	mutations: {
		set_orm: (state, orm) => state.$orm = orm,
	},
	actions: {
		async init({ commit, dispatch }, $orm) {
			commit('set_orm', $orm)

			await dispatch('auth/refresh_token');
			await dispatch('auth/get_user');
			await dispatch('campaign/get_items');
		},
		handle_event: async ({ dispatch }, { event, data }) => {
			//if (event == 'load_profile') dispatch('auth/get_profile');
		},
	},
	modules: {
		auth,
		navigation,
		campaign
	},
	plugins: [createPersistedState({
		paths: ['auth']
	})]
});
