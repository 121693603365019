import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const localizedFormat = require('dayjs/plugin/localizedFormat');
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekday = require('dayjs/plugin/weekday')

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear)
dayjs.extend(weekday)

require('dayjs/locale/en')

export default dayjs