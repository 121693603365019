import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/';

Vue.use(VueRouter);
import auth from './auth.js'
import tabs from './tabs.js'
import campaign from './campaign.js'

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		//main layout
		{
			path: '/',
			component: () => import('@/views/MainLayout'),
			children: [
				campaign,
				tabs,

				{
					name: 'UserSettings',
					path: '/user/settings',
					component: () => import('@/views/user/Settings'),
					meta: {
						authRequired: true
					},
				},

			]
		},
		
		//empty layout
		auth,

		//error
		{
			path: '*',
			component: () => import('@/views/EmptyLayout'),
			children: [
				{
					path: '',
					component: () => import('@/views/empty/Error'),
				},
			],
		},
	]
})


router.beforeEach((to, from, next) => {
	let authenticated = store.getters['auth/authenticated'],
		approved = store.getters['auth/approved'],
		metaRedirect = to.meta.redirect;

	if (to.meta.authRequired) console.log('authRequired', to.fullPath, 'authenticated: ' + authenticated);
	if (to.meta.logedoutRequired) console.log('logedoutRequired', to.fullPath, 'authenticated: ' + authenticated);

	if (to.meta.authRequired && !authenticated) return router.push(metaRedirect || '/auth/login');
	if (to.meta.logedoutRequired && authenticated) return router.push(metaRedirect || '/');

	if (approved === false && to.fullPath !== '/auth/approve') return router.push('/auth/approve');

	next();
});

export default router;
