/* LINKS */
const GET_LINK_WITH_STATS_FRAGMENT = `
    id
    name
    redirect_link
    short_link
    created_at
    stats_byday(args: {
        date_from: $startDate, 
        date_to: $endDate
    }){
        users
        hits
    }
`
const GET_LINK_WITH_STATS_TAG_FRAGMENT = `
    ${GET_LINK_WITH_STATS_FRAGMENT}
    tags {
        tag {
            id
            name
        }
    }
`
export const getLinksWithStats = `
    query GetLinksWithStatsAndFilters($startDate: timestamptz, $endDate: timestamptz, $campaign: bigint) {
        links (
            where: {campaign_id: {_eq: $campaign}}
        ) {
            ${GET_LINK_WITH_STATS_TAG_FRAGMENT}
        }
    }
`
export const getLinkByPKWithStats = `
    query GetLinkByPKWithStatsAndFilters($startDate: timestamptz, $endDate: timestamptz, $id: bigint!) {
        links_by_pk (id: $id) {
            ${GET_LINK_WITH_STATS_TAG_FRAGMENT}
        }
    }
`


/* TAGS */
const GET_TAG_WITH_STATS_FRAGMENT = `
    id
    name
    created_at
    stats_byday(args: {
        date_from: $startDate, 
        date_to: $endDate
    }){
        users
        hits
    }
`
const GET_TAG_WITH_STATS_LINKS_FRAGMENT = `
    ${GET_TAG_WITH_STATS_FRAGMENT}
    links {
        link {
            ${GET_LINK_WITH_STATS_FRAGMENT}
        }
    }
`
export const getTagsWithStats = `
    query GetTagsWithStatsAndFilters($startDate: timestamptz, $endDate: timestamptz, $campaign: bigint) {
        tags (
            where: {campaign_id: {_eq: $campaign}}
        ){
           ${GET_TAG_WITH_STATS_LINKS_FRAGMENT}
        }
    }
`


/* CAMPAIGN */
const CAMPAIGN_FRAGMENT = `
    id
    name
    created_at
`
const CAMPAIGN_STATS_FRAGMENT = `
    stats_total(args: {
        date_from: $startDate, 
        date_to: $endDate
    }){
        users
        hits
    }
    stats_byhour(args: {
        date_from: $startDate, 
        date_to: $endDate
    }){
        hour
        users
        hits
    }
`
const CAMPAIGN_LINKS_TAGS_AGGREGATE_FRAGMENT = `
    links_aggregate {
        aggregate {
            count
        }
    }
    tags_aggregate {
        aggregate {
            count
        }
    }
`
export const getCampaignsWithStatsAndAggregate = `
    query GetCampaignsWithStatsAndFilters($startDate: timestamptz, $endDate: timestamptz) {
        campaign {
            ${CAMPAIGN_FRAGMENT}
            ${CAMPAIGN_STATS_FRAGMENT}
            ${CAMPAIGN_LINKS_TAGS_AGGREGATE_FRAGMENT}
        }
    }
`
export const getCampaign = `
    query GetCampaignByPKWithStatsAndFilters($id: bigint!) {
        campaign_by_pk (id: $id) {
            ${CAMPAIGN_FRAGMENT}
        }
    }
`

