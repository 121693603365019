/* promise */
exports.to = function(promise) {
	return promise.then(data => {
		return [null, data]
	})
	.catch(err => [err])
}

exports.sleep = function (ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

exports.isObject = function(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
};
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
exports.mergeDeep = function(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (exports.isObject(target) && exports.isObject(source)) {
		for (const key in source) {
			if (exports.isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				exports.mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return exports.mergeDeep(target, ...sources);
};
exports.getNested = function (obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}

exports.parseJwt = function(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};

exports.invertColor = function(hex, bw) {
	function padZero(str, len) {
		len = len || 2;
		var zeros = new Array(len).join('0');
		return (zeros + str).slice(-len);
	}
	
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

exports.copyTextToClipboard = function(text) {
    var textArea = document.createElement("textarea");
  
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
  
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    textArea.style.padding = 0;
  
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.log('Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  exports.findLastIndex = function(array, searchKey, searchValue) {
    var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
}