const { Hasura } = require('hasura-om')
const { generateTables } = require('./hasura/tables')
const __ = require('./utils')


function createHasuraInstance(token) {
	var orm = new Hasura({
		graphqlUrl: process.env.VUE_APP_HASURA_URL,
		jwt: token,
		wsConnectionSettings: {
			webSocketImpl:  WebSocket
		}
	})

	orm.queryRAW = async function(parameters, settings = {}, requestSettings = {}) {
		settings = __.mergeDeep( {}, orm.params.query, settings );

		let { query, variables } = parameters
		const [err, response] = await orm.$gql.run({
			query,
			variables,
			requestSettings,
		});

		return [err, response];
	}

	orm.subscribeRAW = async function(parameters, callback, settings = {}) {
		settings = __.mergeDeep( {}, orm.params.subscribe, settings );
		let { query, variables } = parameters

		const unsub = orm.$ws.run({
			query,
			variables,
			callback
		});

		return unsub; // Unsubscribe
	}

	/* 
		parameters:
			query
			variables
	*/
	orm.queryAndSubscribeRAW = async function(parameters, callback, settings = {}) {
		settings = __.mergeDeep( { skipFirst: true }, orm.params.query, settings );
		
		callback(await orm.queryRAW(parameters, settings));

		let skippedFirst = !settings.skipFirst;
		const subSettings = __.mergeDeep({}, orm.params.subscribe, settings);

		parameters.query = parameters.query.replace('query', 'subscription')

		return orm.subscribeRAW(
			parameters,
			(response) => {
				if (skippedFirst === true) {
					callback(response);
				} else {
					skippedFirst = true;
				}
			},
			subSettings,
		);
	}

	orm = generateTables(orm)

	orm.table('links')
		.createFragment('with_stats', [
			orm.table('links').fragment('base'),
			`
				total_hits {
					hits
					users
				}
				tags {
					tag {
						id
						name
					}
				}
			`
		])

	orm.table('tags')
		.createFragment('with_links_stats', [
			orm.table('tags').fragment('base'),
			`
				total_hits {
					hits
					users
			  	}
				links {
					link {
						id
						name
						redirect_link
						short_link
						total_hits {
							hits
							users
						}
					}
				}
			`
		])

	console.log(orm)

	return orm
}

export default createHasuraInstance