export default {
    path: '/',
    component: () => import('@/views/index/Layout'),
    children: [
        {
            name: 'CampaignList',
            path: '/',
            component: () => import('@/views/index/CampaignList'),
            meta: {
                authRequired: true
            },
        }
    ]
}