import { $http } from '../plugins/axios';
import __ from '../plugins/utils';
import log from '../plugins/log';

export default {
	namespaced: true,
	state: {
		jwt_token: null,
		user: null,
		api_authenticated: null,
	},
	getters: {
		defaultRole(getters) {
			return getters.authenticated ? 'user' : 'anonymous';
		},
		authenticated(state) {
			return state.user && state.user.id ? true : false;
		},
		approved(state, getters) {
			if(!getters.authenticated) return null;
			return state.user.is_approved ? true : false;
		},
		masked_email(state) {
			return state.user ? state.user.email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c) : false;
		},
	},
	mutations: {
		set_user: (state, user) => (state.user = user),
		set_jwt_token: (state, token) => (state.jwt_token = token),
		set_api_authenticated: (state, authenticated) => (state.api_authenticated = authenticated),
	},
	actions: {
		logout: async ({ dispatch }) => {
			var response = await __.to($http.post('/auth/logout'));
			dispatch('parseAuthResponse', response);
			window.location.reload();
		},
		refresh_token: async ({ dispatch }) => {
			var response = await __.to($http.post('/auth/refresh_token'));
			dispatch('parseAuthResponse', response);
		},
		get_user: async ({ rootState, state, commit }) => {
			if(state.jwt_token) {
				let jwt_parsed = __.parseJwt(state.jwt_token),
					user_id = jwt_parsed.hasura_claims['x-hasura-user-id']
		
				var [err, user] = await rootState.$orm.query(
					{ user: { where: { id: { _eq: user_id } } } }, 
					{ getFirst: true }
				)
				await commit('set_user', user);
			}
		},
		parseAuthResponse: async ({ rootState, commit }, [err, response]) => {
			if (err) return log.error('store.actions.auth.parseAuthResponse', err);
			log.debug('store.actions.auth.parseAuthResponse', response.data);

			if (response.data.success) {
				commit('set_jwt_token', response.data.token);
				rootState.$orm.updateParams({
					'jwt': response.data.token
				})

				if (!response.data.authenticated) {
					commit('set_user', null);
					commit('set_api_authenticated', false);
				} else {
					commit('set_api_authenticated', true);
				}
			}
		},
	},
};
